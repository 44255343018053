import React, { useState, useEffect } from 'react';
import { getSettings } from '../../services/SettingsService';
import { Link } from 'react-router-dom'; // Para navegação interna
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import Toast from '../../components/Toast';
import HelpRow from './GraphicAnalysisRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { getAllGraphicAnalysisService } from '../../services/GraphicAnalysisService';

export default function GraphicAnalysis() {
    const [images, setImage] = useState([]);
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [hasAccess, setHasAccess] = useState(true);  // Estado para controlar o acesso
    const [count, setCount] = useState(0); // Total de vídeos para calcular o número de páginas
    const [page, setPage] = useState(1); // Página atual



    // Função para buscar os vídeos com base na página atual
    const fetchImage = async (page = 1) => {
        try {
            const response = await getAllGraphicAnalysisService(page); // Passa apenas a página
            setImage(response.rows); // Vídeos retornados
            setCount(response.total); // Total de vídeos para calcular a paginação
        } catch (error) {
            console.error('Erro ao buscar vídeos', error);
            setNotification({ type: 'error', text: 'Erro ao carregar vídeos' });
        }
    };

    useEffect(() => {
        fetchImage(page); // Carrega os vídeos ao montar o componente ou alterar a página
        getSettings()
            .then(settings => {
                if (settings.limitId !== 3 && settings.limitId !== 3 && settings.limitId !== 3) {
                    setHasAccess(false);
                    setNotification({
                        type: 'blue',
                        text: (
                            <div>
                                Access to the Glod plan. Access this page.{' '}
                                <Link to="/pay" className="btn btn-link p-3" >Go to Subscription Plan</Link>
                            </div>
                        ),
                    });
                }
            })
            .catch(err => {
                setHasAccess(false);
                setNotification({ type: 'danger', text: 'Failed to fetch settings. Please try again later.' });
            });
    }, [page]);

    if (!hasAccess) {
        return (
            <>
                <Menu />
                <main className="content">
                    <div className="alert alert-danger mt-3">
                        {notification.text}
                    </div>
                    <Footer />
                </main>
            </>
        );
    }

    const handlePageChange = (_, value) => {
        setPage(value); // Atualiza a página atual
    };


    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Graphic Analysis </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <TableContainer component={Paper}>
                            <Table className="table table-hover" aria-label="collapsible table">
                                <TableHead>
                                    <TableRow className="d-block mb-4 mb-md-0">
                                        <TableCell className="h4">Graphic Analysis</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="border-gray-200">
                                    {images.map((image) => (
                                        <HelpRow key={image.id} row={image} />
                                    ))}
                                </TableBody>
                            </Table>
                            <br></br>
                            <Pagination
                                count={Math.ceil(count / 8)} // Número total de páginas
                                page={page}
                                onChange={handlePageChange} // Lida com a mudança de página
                            />
                            <br></br>
                        </TableContainer>

                    </div>
                </div>

                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}
