import axios from './BaseService';

const VIDEO_URL = `${process.env.REACT_APP_API_URL}/video/`;

export async function getVideo(id) {
    const videoUrl = `${VIDEO_URL}${id}`;
    const response = await axios.get(videoUrl);
    return response.data;//{count, rows}
}

export async function getAllVideo(page = 1, ) {
    const videoUrl = `${VIDEO_URL}all?page=${page}`;
    const response = await axios.get(videoUrl);
    return response.data;
}

export async function saveVideo(newformData) {
    let response
    response = await axios.post(VIDEO_URL, newformData, {
        headers: { 'Content-Type': 'multipart/form-data' }  // Adiciona cabeçalho para multipart
    });

return response.data;
}