import axios from './BaseService';

const URL = `${process.env.REACT_APP_API_URL}/graphic/`;

export async function getGraphicAnalysisService(id) {
    const videoUrl = `${URL}${id}`;
    const response = await axios.get(videoUrl);
    return response.data;//{count, rows}
}

export async function getAllGraphicAnalysisService(page = 1, ) {
    const videoUrl = `${URL}all?page=${page}`;
    const response = await axios.get(videoUrl);
    return response.data;
}

export async function saveGraphicAnalysisService(newformData) {
    let response
    response = await axios.post(URL, newformData, {
        headers: { 'Content-Type': 'multipart/form-data' }  // Adiciona cabeçalho para multipart
    });

return response.data;
}
