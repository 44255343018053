import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/**
 * props:
 * - data
 * - onClick
 */
function HelpRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <div className="flex items-center space-x-4">
                        <h3 className="font-semibold text-lg text-gray-600">{row.title}</h3>
                        {/* <h3 className="text-lg font-medium text-blue-400">{row.title}</h3> */}
                    </div>
                </TableCell>
                <TableCell align="right">{row.videoId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                            Video and details in the description
                            </Typography>
                            <Table size="small" aria-label="video details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Video</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {/* Player de Vídeo */}
                                                <video  width="420" height="340" controls>
                                                    <source src={row.s3Url} type="video/mp4" />
                                                    Seu navegador não suporta o elemento de vídeo.
                                                </video>

                                                {/* Descrição do Vídeo */}
                                                <Box sx={{ marginLeft: 40 }}>
                                                    <Typography variant="body1" color="textSecondary" sx={{ whiteSpace: 'pre-line' }}>
                                                        {row.description} {/* Exibe a descrição do vídeo */}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default HelpRow;