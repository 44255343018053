import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import Toast from '../../components/Toast';
import SelectSymbol from '../../components/SelectSymbol';
import MyStrategies from './MyStrategies/MyStrategies';
import SharedStrategies from './SharedStrategies/SharedStrategies';
import { getSettings } from '../../services/SettingsService';
import { Link } from 'react-router-dom'; // Para navegação interna

function Strategies() {

    const [symbol, setSymbol] = useState('');
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [hasAccess, setHasAccess] = useState(true);  // Estado para controlar o acesso


    // Função para verificar o limite de plano
    useEffect(() => {
        getSettings()
            .then(settings => {            
                if (settings.limitId !== 1 && settings.limitId !== 3 && settings.limitId !== 5) {
                    setHasAccess(false);
                    setNotification({
                        type: 'danger',
                        text: (
                            <div>
                                You need to update your plan to access this page.{' '}
                                <Link to="/pay" className="btn btn-link p-3" >Go to Payment</Link> <a>train that is signature (Premium  or Gold) </a>
                            </div>
                        ),
                    });
                }
            })
            .catch(err => {
                setHasAccess(false);
                setNotification({ type: 'danger', text: 'Failed to fetch settings. Please try again later.' });
            });
    }, []);

    function onSymbolChange(event) {
        setSymbol(event.target.value);
    }

    if (!hasAccess) {
        return (
            <>
                <Menu />
                <main className="content">
                    <div className="alert alert-danger mt-3">
                        {notification.text}
                    </div>
                    <Footer />
                </main>
            </>
        );
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">My Strategies</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <div className="me-2">
                                <SelectSymbol symbol={symbol} disabled={false} onChange={onSymbolChange} showAny={false} onlyFavorites={false} />
                            </div>
                            <button id="btnNewStrategy" className="btn btn-gray-900 animate-up-2" data-bs-toggle="modal" data-bs-target="#modalStrategy">
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" /></svg>
                                New Strategy
                            </button>
                        </div>
                    </div>
                </div>
                <MyStrategies symbol={symbol} onNotification={setNotification} />
                <SharedStrategies symbol={symbol} onNotification={setNotification} />
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}

export default Strategies;
