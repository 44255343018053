import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import HelpRow from './HelpRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Footer from '../../components/Footer';
import Toast from '../../components/Toast';

import { getAllVideo } from '../../services/VideoService';

export default function VideoTable() {
    const [videos, setVideos] = useState([]); // Lista de vídeos
    const [filteredVideos, setFilteredVideos] = useState([]); // Vídeos filtrados
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [count, setCount] = useState(0); // Total de vídeos para calcular o número de páginas
    const [page, setPage] = useState(1); // Página atual
    const [searchTerm, setSearchTerm] = useState(''); // Termo de pesquisa

    // Função para buscar os vídeos com base na página atual
    const fetchVideos = async (page = 1) => {
        try {
            const response = await getAllVideo(page); // Passa apenas a página
            setVideos(response.rows); // Vídeos retornados
            setCount(response.total); // Total de vídeos para calcular a paginação
        } catch (error) {
            console.error('Erro ao buscar vídeos', error);
            setNotification({ type: 'error', text: 'Erro ao carregar vídeos' });
        }
    };

    // Filtra os vídeos com base no searchTerm
    useEffect(() => {
        if (searchTerm) {
            setFilteredVideos(videos.filter(video =>
                video.title.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredVideos(videos);
        }
    }, [searchTerm, videos]);

    useEffect(() => {
        fetchVideos(page); // Carrega os vídeos ao montar o componente ou alterar a página
    }, [page]);

    const handlePageChange = (_, value) => {
        setPage(value); // Atualiza a página atual
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Atualiza o termo de pesquisa
    };

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Support</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">

                        <TableContainer component={Paper}>
                            <Table className="table table-hover" aria-label="collapsible table">
                                <TableHead>
                                    <TableRow className="d-block mb-4 mb-md-0">
                                        <TableCell className="h4">User Support</TableCell>
                                        {/* Campo de busca */}
                                        <div className="d-flex justify-content-between mb-3">
                                            <input
                                                type="text"
                                                placeholder="Search by title"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                className="form-control"
                                                style={{ width: '300px' }}
                                            />
                                        </div>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="border-gray-200">
                                    {filteredVideos.map((video) => (
                                        <HelpRow key={video.id} row={video} />
                                    ))}
                                </TableBody>
                            </Table>
                            <br />
                            <Pagination
                                count={Math.ceil(count / 8)} // Número total de páginas
                                page={page}
                                onChange={handlePageChange} // Lida com a mudança de página
                            />
                            <br />
                        </TableContainer>
                    </div>
                </div>

                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}
